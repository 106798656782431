import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'

interface NewRelicConfig {
  accountId?: string
  trustKey?: string
  agentId?: string
  licenseKey?: string
  applicationId?: string
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig().public.newrelic as NewRelicConfig
  const { accountId, trustKey, agentId, licenseKey, applicationId } = config

  if (!accountId || !trustKey || !agentId || !licenseKey || !applicationId)
    return

  // using the NR-maintained browser agent package: https://github.com/newrelic/newrelic-browser-agent
  // populate using values in copy-paste JavaScript snippet.
  const options = {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net'] }
    }, // NREUM.init
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: licenseKey,
      applicationID: applicationId,
      sa: 1
    }, // NREUM.info
    loader_config: {
      accountID: accountId,
      trustKey: trustKey,
      agentID: agentId,
      licenseKey: licenseKey,
      applicationID: applicationId
    } // NREUM.loader_config
  }

  // the agent loader code executes immediately on instantiation.
  new BrowserAgent(options)
})
