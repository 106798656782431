import { VueReCaptcha } from 'vue-recaptcha-v3'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()

  nuxtApp.vueApp.use(VueReCaptcha, {
    siteKey: runtimeConfig.public.vueAppRecaptcha,
    loaderOptions: {
      useEnterprise: true,
      autoHideBadge: false,
      explicitRenderParameters: {
        badge: 'bottomright'
      }
    }
  })
})
