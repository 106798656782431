import { Loader, LoaderOptions } from '@googlemaps/js-api-loader'

export default defineNuxtPlugin(async () => {
  const getLoader = (options: Partial<LoaderOptions> = {}) => {
    const runtimeConfig = useRuntimeConfig().public
    return new Loader({
      apiKey: runtimeConfig.googleSearchKey,
      version: 'weekly',
      ...options
    })
  }

  // create loader
  const loader = getLoader()

  // import libraries
  const { Map } = await loader.importLibrary('maps')
  const { AdvancedMarkerElement } = await loader.importLibrary('marker')
  const { Autocomplete } = await loader.importLibrary('places')
  const { Geocoder } = await loader.importLibrary('geocoding')

  const getLocation = (
    input: HTMLElement,
    latLng: google.maps.LatLng,
    options: google.maps.MapOptions
  ) => {
    const map = new Map(input, options)
    return new AdvancedMarkerElement({
      map,
      position: latLng
    })
  }

  const makeAutoComplete = (
    input: HTMLInputElement,
    options = { types: ['(cities)'] }
  ) => {
    const autocomplete = new Autocomplete(input, options)
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      input.dispatchEvent(new CustomEvent('changed', { detail: place }))
    })
  }

  const reverseGeocode = (lat: number, lng: number) => {
    const geocoder = new Geocoder()
    const latLng = new google.maps.LatLng(lat, lng)

    return geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results) {
          return results
        } else {
          console.error('Geocoder failed due to: ' + status)
          return status
        }
      }
    })
  }

  return {
    provide: {
      maps: {
        getLocation,
        makeAutoComplete,
        reverseGeocode
      }
    }
  }
})
