import { stringEmpty } from '~/utils/helpers'
import { useMainStore } from '~/stores/main'

export default function () {
  /**
   * Helper function to get data from either the middleware or front door API
   * @param middlewareRelative - Relative path to middleware API endpoint
   * @param frontDoorRelative - Relative path to front door API endpoint
   * @param postProcessing - Optional function to process data before returning
   */
  const fetch = async <Payload, Result = Payload>(
    middlewareRelative: string,
    frontDoorRelative: string,
    postProcessing = (data: Payload) => data as unknown as Result
  ) => {
    if (stringEmpty(middlewareRelative) || stringEmpty(frontDoorRelative))
      return

    const url = getUrl(middlewareRelative, frontDoorRelative)
    const data = await $fetch<Payload>(url)

    return postProcessing(data)
  }

  /**
   * Helper function to either call middleware or get GraphQL data from FrontDoor API
   * @param middlewareRelative - Relative path to middleware API endpoint
   * @param graphQlId - GraphQL ID to query
   * @param postProcessing - Optional function to process data before returning
   */
  const fetchGraphQl = async <Payload, Result = Payload>(
    middlewareRelative: string,
    graphQlId: string,
    postProcessing = (data: Payload) => data as unknown as Result
  ) => {
    if (stringEmpty(graphQlId)) return

    return fetch(
      middlewareRelative,
      `/api/ssc/e2e.gql/query?id=${graphQlId}`,
      postProcessing
    )
  }

  /**
   * Helper function to get image url from either the middleware or front door API
   * @param path - Relative path to image
   */
  const getImgUrl = (path: string) => {
    if (stringEmpty(path)) return ''

    return getUrl(`/api/assets/img?path=${path}`, `/api/-/media/Images/${path}`)
  }

  /**
   * Helper function to get url from either the middleware or front door API
   * @param middlewareRelative - Relative path to middleware API endpoint
   * @param frontDoorRelative - Relative path to front door API endpoint
   */
  const getUrl = (middlewareRelative: string, frontDoorRelative: string) => {
    const { origin, frontDoorApiUrl, useFrontDoorApi } = getConfig()

    return useFrontDoorApi
      ? `${frontDoorApiUrl}${frontDoorRelative}`
      : `${origin}${middlewareRelative}`
  }

  /**
   * Helper function to get config from store
   * @returns {{origin: string, useFrontDoorApi: boolean, frontDoorApiUrl: string}}
   */
  const getConfig = (): {
    origin: string
    useFrontDoorApi: boolean
    frontDoorApiUrl: string
  } => {
    const config = useRuntimeConfig().public
    let { origin } = useMainStore()

    // todo: get rid of this workaround, sometimes origin comes through as '', fallback to site_url if useSiteUrlAsOrigin is true, which is the current value for all vital environments
    if (!origin && config.useSiteUrlAsOrigin) {
      origin = config.siteUrl
    }

    const frontDoorApiUrl = config.frontDoorApiUrl
    const useFrontDoorApi =
      config.useFrontDoorApi && !stringEmpty(frontDoorApiUrl)

    return {
      origin,
      frontDoorApiUrl,
      useFrontDoorApi
    }
  }

  return { fetch, fetchGraphQl, getImgUrl }
}
