import { defineStore } from 'pinia'
import { useFeatureFlagStore } from './featureFlag'
import { useSiteConfigStore } from './siteConfig'

export const useMainStore = defineStore('main', {
  state: () => ({
    origin: ''
  }),
  actions: {
    async init() {
      if (!process.server) return

      this.origin = getOrigin()
    }
  }
})

const getOrigin = () => {
  const config = useRuntimeConfig()
  const headers = useRequestHeaders(['x-forwarded-proto'])
  const event = useRequestEvent()
  const req = event?.node.req

  // if 'x-forwarded-proto' header is present it will also be set to 'https' so it can be used as protocol
  // if not just use 'http'
  // in nuxt 2, 'x-forwarded-proto' value was either http or https. now it seems it can also be IPv6 so
  // adding a test for anything other than http/https also defaults to http (bit of a guess)
  const forwardedProto = headers['x-forwarded-proto'] || ''
  const protocol = ['http', 'https'].includes(forwardedProto)
    ? headers['x-forwarded-proto']
    : 'http'

  // temporary fix for re-written preprod and prod host headers
  let host: string | undefined
  if (req?.headers?.host === 'e2e.service.mcs.tafensw.edu.au') {
    // if prod GSLB host, then set as public facing prod host
    host = 'beta.tafensw.edu.au'
  } else if (
    req?.headers?.host === 'e2e.service.mcs.preprod.tafenswtest.edu.au'
  ) {
    // if preprod GSLB host, then set as public facing prod host
    host = 'beta.preprod.tafenswtest.edu.au'
  } else {
    host = req?.headers?.host
  }

  const siteUrl = new URL(config.public.siteUrl)
  return config.public.useSiteUrlAsOrigin
    ? `${protocol}://${siteUrl.host}`
    : `${protocol}://${host}`
}
