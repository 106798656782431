<template>
  <component
    :is="getComponentType()"
    v-bind="$attrs"
    class="font-bold text-base px-4 py-2 inline-flex items-center shadow-md"
    :class="getClassList()"
    :target="targetValue"
  >
    <slot />
  </component>
</template>

<script>
export default {
  // TODO: Should we force href or role="button" ? Add aria-disabled="true"
  name: 'A11yButton',
  props: {
    color: {
      type: String,
      default: 'blue'
    },
    component: {
      type: String,
      default: ''
    },
    linktype: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    targetValue() {
      return this.linktype === 'external' ? '_blank' : '_self'
    }
  },
  methods: {
    getComponentType() {
      let componentType
      // If the component prop exists use it
      if (this.component) {
        // dynamically resolved NuxtLink component
        this.component === 'nuxt-link'
          ? (componentType = resolveComponent('NuxtLink'))
          : (componentType = this.component)
        // Else check for url and set anchor
      } else {
        componentType = this.$attrs?.href ? 'a' : 'button'
      }
      return componentType
    },
    getClassList() {
      let color = this.color
      // const { variant, disabled } = props

      // if disabled return disabled styles
      if (this.disabled) {
        return 'text-gray-700 bg-gray-100 border border-gray-300 shadow-none cursor-not-allowed'
      }
      // Else calculate the colors and return brand styles
      // set primary and secondary defaults
      const variants = {
        primary: 'blue-500',
        secondary: 'gray',
        dark: 'gray-700',
        black: 'black',
        white: 'white'
      }

      if (this.variant) color = variants[this.variant]

      if (color === 'black') {
        return 'bg-black border-black text-white hover:bg-gray-800 focus:bg-gray-800'
      } else if (color === 'white') {
        return 'bg-white border-white text-black hover:bg-gray-200 focus:bg-gray-200'
      } else {
        // if it has lightness value (e.g. blue-300) split base color and lightness
        const isBase = Number.isNaN(parseInt(color.slice(color.length - 3), 10))
        const lightness = isBase
          ? 300
          : parseInt(color.slice(color.length - 3), 10)
        const baseColor = isBase ? color : color.slice(0, color.length - 4)

        return [
          `bg-brand-${baseColor}-${lightness}`,
          `border-brand-${baseColor}-${lightness + 100}`,
          `text-${lightness > 499 ? 'white' : 'black'}`,
          `hover:bg-brand-${baseColor}-${lightness + 100}`,
          `focus:bg-brand-${baseColor}-${lightness + 100}`
        ]
      }
    }
  }
}
</script>
