interface Commands {
  event: [eventName: string, eventParams?: Record<string, unknown>]
}

interface Gtag {
  <Command extends keyof Commands>(
    command: Command,
    ...args: Commands[Command]
  ): void
}

export default function () {
  // for ssr-friendly code, do nothing for server-side execution, or call the
  // applyTag method for client-side code
  const gtag: Gtag = import.meta.server ? () => {} : applyTag

  function applyTag<Command extends keyof Commands>(
    command: Command,
    ...args: Commands[Command]
  ) {
    let data: Record<string, unknown> | undefined = undefined

    switch (command) {
      case 'event':
        const [eventName, eventParams] = args
        data = {
          event: eventName,
          ...eventParams
        }
    }

    if (data) window.dataLayer?.push(data)
  }

  return { gtag }
}
