export const options = {
  "baseURL": "http://localhost:3000/",
  "browserBaseURL": "/",
  "proxyHeaders": true,
  "proxyHeadersIgnore": [
    "accept",
    "connection",
    "cf-connecting-ip",
    "cf-ray",
    "content-length",
    "content-md5",
    "content-type",
    "host",
    "if-modified-since",
    "if-none-match",
    "x-forwarded-host",
    "x-forwarded-port",
    "x-forwarded-proto"
  ],
  "serverTimeout": 10000,
  "clientTimeout": 25000,
  "https": false,
  "retry": 1,
  "headers": {
    "accept": "application/json, text/plain, */*"
  },
  "credentials": "same-origin",
  "debug": false
}