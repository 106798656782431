<template>
  <div>
    <NuxtLayout :name="layout">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<script setup lang="ts">
const route = useRoute()

const paths = {
  testing: 'showcase',
  international: 'international',
  'study-seeker': 'questionnaire'
}

const config = useRuntimeConfig().public

useHead(() => {
  return {
    link: [
      {
        rel: 'canonical',
        href: config.siteUrl + route.path
      }
    ]
  }
})

const regex = new RegExp(`^/(${Object.keys(paths).join('|')})(/|$)`, 'mi')

const layout = computed(() => {
  const found = route.path.match(regex)?.[1] as keyof typeof paths
  return paths[found] || 'default'
})
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: opacity 0.25s ease;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
