import { defineStore } from 'pinia'
import { useSitecoreGraphQueryStore } from '~/stores/sitecoreGraphQuery'

import Payload = models.server.api.graphQuery.globalComponents.Root

interface State {
  config: models.stores.siteConfig.Config
}

export const useSiteConfigStore = defineStore('siteConfig', {
  state: (): State => ({
    config: {}
  }),
  actions: {
    async fetch() {
      if (this.config && Object.keys(this.config).length) return

      const sitecoreGraphQuery = useSitecoreGraphQueryStore()
      const { data } = await sitecoreGraphQuery.fetch<Payload>(
        '9B903B57-1B2C-405C-8991-6AACC7C9C1F9'
      )

      const { mapNavigation, parseBusinessRules, parseFeatureFlags } =
        useSitecoreGqlUtils()

      this.config = {
        footer: data?.footer,
        globalNav: mapNavigation(data?.globalNav?.children),
        businessRules: parseBusinessRules(data?.businessRules),
        featureFlags: parseFeatureFlags(data?.featuredFlag)
      }
    }
  }
})
