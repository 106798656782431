<template>
  <div v-click-outside="closeDdown" class="relative">
    <Dropdown ref="ddown">
      <template #trigger="{ toggle, active, uuid }">
        <button
          class="text-base px-4 py-3 inline-flex items-center justify-between space-x-2 bg-white border md:border-l-0 md:border-r-0 border-gray-300 w-full md:w-auto md:text-left"
          :aria-expanded="`${active}`"
          :aria-controls="uuid"
          @click="toggle"
          @keyup="jumpToCampus"
        >
          <span class="md:inline-block md:w-32 lg:w-48 md:truncate">{{
            userLocation.loc || 'All locations'
          }}</span>
          <Icon
            name="IconChevronDown"
            class="transform transition-all duration-200"
            :class="active ? 'rotate-180' : ''"
            :title="active ? 'hide locations' : 'show more locations'"
          />
        </button>
      </template>
      <div
        class="overflow-y-scroll w-full md:w-64 border"
        style="max-height: 32rem"
        @keyup="jumpToCampus"
      >
        <div class="px-4 py-3">
          <label
            :class="
              userLocation.errorMessage ? 'cursor-not-allowed opacity-75' : ''
            "
            class="flex items-center"
          >
            <input
              v-model="geolocation"
              :class="[
                geolocation ? '-m-px border-2 border-brand-blue-400' : 'border',
                userLocation.errorMessage ? 'cursor-not-allowed' : ''
              ]"
              :value="geolocation"
              type="checkbox"
              :disabled="userLocation.errorMessage"
              class="text-brand-blue-400 border border-brand-grey-500 mr-3 w-4 h-4 rounded focus:border-brand-blue-400 focus:ring focus:ring-offset-0 focus:ring-brand-blue-400 focus:ring-opacity-50"
            />
            Use my location
          </label>

          <div>
            <p
              v-if="userLocation.isSearching"
              class="flex items-center gap-1 pt-3 text-gray-700"
            >
              <span>Searching</span> <Spinner />
            </p>
            <p v-if="userLocation.errorMessage" class="pt-1 text-web-red">
              {{ userLocation.errorMessage }}
            </p>
          </div>
        </div>
        <p class="px-4 mt-2">
          <strong>or select a campus</strong>
        </p>
        <ul class="pt-3 px-4">
          <li v-if="userLocation.loc">
            <button
              class="w-full py-2 text-left"
              type="button"
              @click="select()"
            >
              All locations
            </button>
          </li>
          <li>
            <button
              class="w-full py-2 text-left"
              @click="select(null, { suburb: 'TAFE Digital (online)' })"
            >
              TAFE Digital (online)
            </button>
          </li>
          <li v-for="(campus, i) in sortedCampuses" :key="i" ref="campusList">
            <button
              class="w-full py-2 text-left"
              type="button"
              @click="select(campus)"
            >
              {{ campus.name }} <span class="sr-only">selected</span>
            </button>
          </li>
        </ul>
      </div>
    </Dropdown>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useUserStore } from '~/stores/user'
import { useCampusStore } from '~/stores/campus'

const getElementText = (li) =>
  li.firstChild.firstChild.nodeValue.trim().toUpperCase()

export default defineNuxtComponent({
  name: 'SearchCoursesNearMe',
  async setup() {
    const userStore = useUserStore()
    const campusStore = useCampusStore()

    await useAsyncData(() => campusStore.fetchAll().then(() => true))

    return { userStore }
  },
  data: () => ({
    isSearching: false,
    mapsData: {}
  }),
  computed: {
    ...mapState(useCampusStore, { campuses: 'items' }),
    ...mapState(useUserStore, { userLocation: 'location' }),
    geolocation: {
      get() {
        return this.userStore.useGeolocation
      },
      async set(value) {
        if (value) {
          this.userStore.useGeolocation = true
          await this.userStore.findSuburb()
        } else {
          console.log('useGeolocation: setting location to null')
          this.userStore.setLoc({
            suburb: null,
            lat: null,
            lng: null
          })
        }
      }
    },
    sortedCampuses() {
      // TODO: filter out when name ===
      // const filteredLocations = ['test', 'virtual']
      return [...this.campuses].sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    select(campus = null, location = null) {
      this.selectCampus(campus)

      if (location) {
        console.log('select: setting location to', location)
        this.userStore.setLoc(location)
      }

      this.closeDdown()
    },
    closeDdown() {
      this.$refs.ddown?.close()
    },
    selectCampus(campus) {
      const value = () => {
        if (campus) {
          return {
            suburb: campus.name,
            lat: campus.location?.lat,
            lng: campus.location?.lng
          }
        } else {
          return {
            suburb: null,
            lat: null,
            lng: null
          }
        }
      }
      console.log('selectCampus: setting location to', value())
      this.userStore.setLoc(value())
      this.userStore.useGeolocation = false
      // this.userStore.setGeolocation(false)
    },
    jumpToCampus({ key }) {
      const startingLetter = key.toUpperCase()
      let campus = this.userLocation.loc?.toUpperCase() || null

      // if key doesn't match current campus, restart search
      if (!campus?.startsWith(startingLetter)) {
        campus = null
      }

      // get all campuses with names that start with key
      const selection = this.sortedCampuses.filter((c) =>
        c.name.toUpperCase().startsWith(startingLetter)
      )

      if (selection.length > 0) {
        let index = 0

        // campus of the same starting letter has previously been selected, find its index and
        // display either the next in line or restart at the top if we reached the end
        if (campus) {
          // eslint-disable-next-line prettier/prettier
          index =
            selection.findIndex((c) => c.name.toUpperCase() === campus) + 1

          if (index >= selection.length) {
            index = 0
          }
        }

        campus = selection[index].name.toUpperCase()
        this.selectCampus(selection[index])

        // find the DOM element of the selected campus and scroll to it
        this.$refs.campusList
          ?.find((li) => getElementText(li) === campus)
          ?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start'
          })
      }
    }
  }
})
</script>
