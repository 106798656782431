import { Oauth2Scheme } from '#auth/runtime'
import { merge } from 'lodash-es'

import type { Auth, Oauth2SchemeOptions } from '#auth/runtime'
import type { SchemePartialOptions } from '@nuxt-alt/auth'

export default class PingFedScheme extends Oauth2Scheme {
  constructor($auth: Auth, options: SchemePartialOptions<Oauth2SchemeOptions>) {
    if (!tryUseNuxtApp()) return

    const { oauthIssuer, oauthClientId } = useRuntimeConfig().public
    const runtimeOptions = getRuntimeOptions(oauthIssuer, oauthClientId)
    const dynamicOptions = merge(options, runtimeOptions)

    super($auth, dynamicOptions)
  }
}

function getRuntimeOptions(issuer?: string, clientId?: string) {
  return {
    endpoints: {
      authorization: issuer ? issuer + '/as/authorization.oauth2' : undefined,
      token: issuer ? issuer + '/as/token.oauth2' : undefined,
      userInfo: issuer ? issuer + '/idp/userinfo.openid' : undefined,
      logout: issuer ? issuer + '/idp/startSLO.ping' : undefined
    },
    clientId: clientId || undefined
  }
}
