<template>
  <section>
    <FetchLoading v-if="loadingState.pending" />
    <FetchError v-else-if="loadingState.error" :error="error" />
    <div v-else>
      <client-only>
        <div>
          <StudySeekerHeader
            v-if="currentStep"
            :current-step-num="currentStepNum"
            :total-steps="studySeekerStore.steps.length"
            :relevant-matches="studySeekerStore.relevantMatchesCount"
            :results-panel-active="showMobileResultsPanel"
            @toggle-results-panel="
              showMobileResultsPanel = !showMobileResultsPanel
            "
          />

          <div class="container mt-8">
            <div class="flex md:mb-32">
              <div class="md:flex w-full">
                <div
                  class="w-full md:w-1/3 self-start"
                  :class="showMobileResultsPanel ? 'hidden mb:block' : 'block'"
                >
                  <StudySeekerStepper
                    ref="studySeekerStepper"
                    :steps="studySeekerStore.steps"
                  />
                </div>
                <StudySeekerControls
                  v-if="!showMobileResultsPanel"
                  :results-panel-active="showMobileResultsPanel"
                />

                <FadeTransition>
                  <StudySeekerResults
                    :sorted-course-areas="sortedCourseAreas"
                    :class="
                      showMobileResultsPanel
                        ? 'block'
                        : 'hidden md:grid ml-10 flex-1'
                    "
                  />
                </FadeTransition>
              </div>
            </div>
          </div>
        </div>
      </client-only>
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useStudySeekerStore } from '~/stores/studySeeker'
import { useCourseAreaStore } from '~/stores/courseArea'

const { sendGtmData } = useHelpers()

export default {
  async setup() {
    useHead({
      title: 'Study Seeker | TAFE NSW'
    })
    definePageMeta({
      name: 'study-seeker-id',
      pageTransition: false
    })
    const { sendApplicablePageGtmData } = useHelpers()
    const studySeekerStore = useStudySeekerStore()
    const courseAreaStore = useCourseAreaStore()
    const loadingState = ref({
      pending: true,
      error: false
    })
    try {
      await studySeekerStore.fetchAll()

      // Fetch all course areas (cached)
      await useAsyncData('courseAreas', () => courseAreaStore.fetchAll())
      loadingState.value.pending = false
    } catch (err) {
      loadingState.value.error = true
      loadingState.value.pending = false
      if (process.server) {
        this.$nuxt.context.res.statusCode =
          err.statusCode || err.response?.status || 500
      }
    }
    const { getCurrentStep: currentStep, showMobileResultsPanel } =
      storeToRefs(studySeekerStore)
    return {
      loadingState,
      studySeekerStore,
      courseAreaStore,
      currentStep,
      showMobileResultsPanel,
      sendApplicablePageGtmData
    }
  },
  computed: {
    currentStepNum() {
      return (
        this.studySeekerStore.getStepIndexById(
          this.studySeekerStore.currentStepId
        ) + 1
      )
    },
    sortedCourseAreas() {
      this.addRemoveMatches()

      return (
        this.courseAreaStore.items
          // Temporary workaround until CMS has field validation implemented
          .filter((area) => area.id && area.name)
          // Sort alphabetically
          .sort((a, b) => a.name.localeCompare(b.name))
          // Sort by number of matches
          .sort(
            (a, b) =>
              this.studySeekerStore.courseAreaMatches(b.name).length -
              this.studySeekerStore.courseAreaMatches(a.name).length
          )
      )
    },
    gtmData() {
      return {
        event: '',
        ecommerce: {
          currencyCode: 'AUD',
          impressions: [
            { name: '', id: '', category: '', dimension1: 'Study Seeker' }
          ]
        }
      }
    }
  },
  mounted() {
    this.sendGtmData('siteBrowsing', this.gtmData)
    this.sendApplicablePageGtmData()

    if (this.studySeekerStore.currentStepId !== this.$route.params.id) {
      this.studySeekerStore.setCurrentStepId(this.$route.params.id)
    }
  },
  methods: {
    sendGtmData,
    addRemoveMatches() {
      // Loop over all course areas
      this.courseAreaStore.items.forEach((courseArea) => {
        // Check courseArea object has filters and the current step ID is a filter key
        if (
          courseArea.filters &&
          this.studySeekerStore.currentStepId in courseArea.filters
        ) {
          // Check all filter tags for current step ID
          courseArea.filters[this.studySeekerStore.currentStepId]?.forEach(
            (tag) => {
              // Check if the filter tag has been captured in answers
              const foundTagInAnswers =
                this.studySeekerStore.answers[
                  this.studySeekerStore.currentStepId
                ]?.includes(tag)

              // Check if the filter tag has been captured in matches under the current course area and the current step ID
              const foundTagInMatched = this.studySeekerStore.matches?.[
                courseArea.name
              ]?.[this.studySeekerStore.currentStepId]?.find(
                (existingTag) => existingTag === tag
              )

              // If filter tag has been removed from answers but is still in matched, remove it from matches
              if (!foundTagInAnswers && foundTagInMatched) {
                this.studySeekerStore.removeMatch(
                  courseArea.name,
                  this.studySeekerStore.currentStepId,
                  tag
                )

                // If filter tag has been added to answers and is not found in matches, add it to matches
              } else if (foundTagInAnswers && !foundTagInMatched) {
                this.studySeekerStore.addMatch(
                  courseArea.name,
                  this.studySeekerStore.currentStepId,
                  tag,
                  this.$viewport.breakpoint
                )
              }
            }
          )
        }
      })
    }
  }
}
</script>
