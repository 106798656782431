import { inject } from 'vue'

export default function () {
  const isInternational = inject('IsInternational', false)

  const getCssClass = (
    brandClass: string | string[],
    internationalClass: string | string[]
  ): string | string[] => {
    return isInternational ? internationalClass : brandClass
  }

  return {
    isInternational,
    getCssClass
  }
}
