export default defineNuxtPlugin(() => {
  const gtagId = useRuntimeConfig().public.gtag.id

  function push(...args: unknown[]) {
    window.dataLayer.push(...args)
  }

  window.dataLayer = window.dataLayer || []
  push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

  useHead({
    script: [
      {
        src: `https://www.googletagmanager.com/gtm.js?id=${gtagId}`,
        async: true
      }
    ]
  })
})
