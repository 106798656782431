<template>
  <div class="flex flex-row items-stretch justify-items-stretch">
    <div class="relative flex-1">
      <input
        :id="id"
        ref="searchInput"
        v-model="searchTerm"
        type="text"
        :placeholder="placeholderText"
        class="bg-white border pl:4 lg:pl-12 py-3 sm:text-base w-full placeholder-brand-grey-300"
        :class="[
          isSearchFocused ? '' : 'cursor-pointer',
          getCssClass('border-gray-300', 'border-int-grey-02')
        ]"
        @keydown.esc="searchTerm = ''"
        @keydown.enter="$emit('search')"
        @focus="setSearchFocused(true)"
        @blur="setSearchFocused(false)"
      />
      <label
        :for="id"
        class="absolute inset-y-0 left-0 m-2 hidden lg:flex flex-row items-center cursor-pointer"
        @click="$refs.searchInput.focus()"
      >
        <Icon name="IconSearch" class="text-3xl"></Icon>
        <span class="sr-only">{{ labelText }}</span>
      </label>

      <button
        v-if="searchTerm"
        type="button"
        class="text-brand-grey-600 absolute inset-y-0 right-0 top-0 mr-4 flex flex-row items-center"
        @click="searchTerm = ''"
      >
        <Icon name="IconClose" class="text-xl text-black"></Icon>
        <span class="sr-only">Clear search</span>
      </button>
    </div>

    <!-- locations -->

    <SearchCoursesNearMe
      v-if="locationSearch && config.featureFlags?.coursesNearMe"
    />

    <A11yButton
      v-if="showButton"
      ref="searchButton"
      component="button"
      variant="primary"
      type="button"
      aria-label="Submit search"
      class="space-x-0"
      @click="$emit('search')"
    >
      <div class="flex-1" />
      <span v-if="buttonText" class="hidden xs:inline">{{ buttonText }}</span>
      <Icon v-else name="IconSearch" class="text-3xl text-white"></Icon>
      <div class="flex-1" />
    </A11yButton>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useUiStore } from '~~/stores/ui'
import { useSiteConfigStore } from '~~/stores/siteConfig'
import useTheme from '~/composables/theme/useTheme'

export default {
  name: 'SearchBar',
  props: {
    id: {
      type: String,
      default: ''
    },
    showButton: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: ''
    },
    placeholderText: {
      type: String,
      default: ''
    },
    labelText: {
      type: String,
      default: 'Search Courses'
    },
    searchParam: {
      type: String,
      default: ''
    },
    buttonStyles: {
      type: String,
      default: 'bg-brand-blue-400 text-white'
    },
    hoverStyles: {
      type: String,
      default: 'hover:bg-brand-blue-600 focus:bg-black focus:outline-black'
    },
    locationSearch: {
      type: Boolean,
      default: false
    },
    delay: {
      type: Number,
      default: 0
    }
  },
  emits: ['search', 'update:searchParam'],
  setup() {
    const { getCssClass } = useTheme()
    return { getCssClass }
  },
  data: () => ({
    timeout: null
  }),
  computed: {
    ...mapState(useUiStore, ['isSearchFocused']),
    ...mapState(useSiteConfigStore, ['config']),
    searchTerm: {
      get() {
        return this.searchParam
      },
      set(value) {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(() => {
          this.$emit('update:searchParam', value)
        }, this.delay)
      }
    }
  },
  methods: {
    ...mapActions(useUiStore, ['setSearchFocused'])
  }
}
</script>
