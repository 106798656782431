import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import http_plugin_nuxt_BmzkAX86rT from "/app/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import auth_plugin_6DDHMgc34l from "/app/.nuxt/auth.plugin.mjs";
import plugin_ghbUAjaD3n from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_4F1kZh3YAB from "/app/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import floating_vue_EIcJ7xiw0h from "/app/.nuxt/floating-vue.mjs";
import plugin_hwlJ5PmHMh from "/app/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import plugin_AUP22rrBAc from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _01_newrelic_client_6RIyUh4KRt from "/app/plugins/01.newrelic.client.ts";
import camelCasify_bxueWwbxoP from "/app/plugins/camelCasify.ts";
import caseInsensitiveEquals_6kK6cSsoIS from "/app/plugins/caseInsensitiveEquals.ts";
import clickOutside_ZB3Eg0wOVk from "/app/plugins/clickOutside.ts";
import gtm_client_OzmBuHLRIb from "/app/plugins/gtm.client.ts";
import maps_client_PojqIiJg46 from "/app/plugins/maps.client.ts";
import markdownit_fsFV40uZ9C from "/app/plugins/markdownit.ts";
import recaptcha_85gNSCNFUU from "/app/plugins/recaptcha.ts";
import scrollSpy_client_OTkuFgQ5Hd from "/app/plugins/scrollSpy.client.ts";
import scrollTo_IIJedyuVwF from "/app/plugins/scrollTo.ts";
import sentenceCase_JXPj5oHmv1 from "/app/plugins/sentenceCase.ts";
import sliderComponent_client_QCohgkk3oP from "/app/plugins/sliderComponent.client.ts";
import slugify_AVRv38arlk from "/app/plugins/slugify.ts";
import throttle_client_gMK1x5Opam from "/app/plugins/throttle.client.ts";
import titleCase_s5QZeZHRRn from "/app/plugins/titleCase.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  http_plugin_nuxt_BmzkAX86rT,
  auth_plugin_6DDHMgc34l,
  plugin_ghbUAjaD3n,
  plugin_client_4F1kZh3YAB,
  floating_vue_EIcJ7xiw0h,
  plugin_hwlJ5PmHMh,
  plugin_AUP22rrBAc,
  _01_newrelic_client_6RIyUh4KRt,
  camelCasify_bxueWwbxoP,
  caseInsensitiveEquals_6kK6cSsoIS,
  clickOutside_ZB3Eg0wOVk,
  gtm_client_OzmBuHLRIb,
  maps_client_PojqIiJg46,
  markdownit_fsFV40uZ9C,
  recaptcha_85gNSCNFUU,
  scrollSpy_client_OTkuFgQ5Hd,
  scrollTo_IIJedyuVwF,
  sentenceCase_JXPj5oHmv1,
  sliderComponent_client_QCohgkk3oP,
  slugify_AVRv38arlk,
  throttle_client_gMK1x5Opam,
  titleCase_s5QZeZHRRn
]