<template>
  <span
    :title="!!title ? title : undefined"
    :aria-hidden="!title"
    class="flex-shrink-0 inline-block fill-current tf-icon"
    :class="{
      'tf-icon--fill': !filled,
      'tf-icon--stroke': hasStroke && !filled
    }"
    v-html="icon"
  />
</template>

<script setup lang="ts">
import { camelCase, startCase } from 'lodash-es'
import { ref, watchEffect } from '#imports'

const props = withDefaults(
  defineProps<{
    name: string
    title?: string
    filled?: boolean
  }>(),
  { filled: false, title: '' }
)

const icon = ref<string | Record<string, any>>('')
let hasStroke = false

async function getIcon() {
  // NB: `cleanName` transforms icon names like 'icon' to 'Icon', and 'IconNAME' to 'IconName'
  const cleanName = startCase(camelCase(props.name)).replace(/ /g, '')
  try {
    const iconsImport = import.meta.glob('assets/icons/**/**.svg', {
      query: '?raw',
      import: 'default',
      eager: false
    }) as Record<string, () => Promise<string>>
    const rawIcon = await iconsImport[`/assets/icons/${cleanName}.svg`]()
    if (rawIcon.includes('stroke')) {
      hasStroke = true
    }
    icon.value = rawIcon
  } catch {
    console.error(
      `[nuxt-icons] Icon '${cleanName}' doesn't exist in 'assets/icons'`
    )
  }
}

await getIcon()

watchEffect(getIcon)
</script>

<style>
.tf-icon svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
}
.tf-icon.tf-icon--fill,
.tf-icon.tf-icon--fill * {
  fill: currentColor !important;
}

.tf-icon.tf-icon--stroke,
.tf-icon.tf-icon--stroke * {
  stroke: currentColor !important;
}
</style>
