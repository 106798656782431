export const stringEmpty = (value?: string | null) =>
  !(value && value.trim().length)

/**
 * Check at least one value in an object is truthy
 * @param {object} obj - An object
 */
export const checkSomeNotEmpty = (
  obj: { [s: string]: unknown } | ArrayLike<unknown> | null | undefined
) => {
  if (!obj) {
    return
  }

  return Object.values(obj).some((value) => value)
}

export const tryParseJSON = (value: string) => {
  try {
    const json = JSON.parse(value)

    if (json && typeof json === 'object') {
      return json
    }
  } catch {}

  return false
}

/**
 * Checks if a provided course type filter option matches the course classification
 * @param {Object} filter Course Type Filter
 * @param type
 */
export const courseTypeFilterMatch = (filter: any, type: string) =>
  !!filter?.value?.includes(type)

export const resolveValue = (
  path: string | string[],
  obj = {},
  separator = '.'
) => {
  // If path is already an array use it,
  // Otherwise split the path into an array using the passed in separator param
  const properties: string[] = Array.isArray(path)
    ? path
    : path.split(separator)

  // Iterate through object properties until inner mosted nested property is reached
  return properties.reduce((prev: any, curr) => {
    return prev && prev[curr]
  }, obj)
}

/**
 * Replaces placeholders, marked using {<field>}, in a string with values from a replacement object
 * @param value String containing placeholders, e.g. 'Hello {userName}'
 * @param replacements Object containing replacement values, e.g. { userName: 'John' }
 * @returns String with placeholders replaced with values, e.g. 'Hello John'
 */
export const formatString = (
  value?: string,
  replacements: Record<string, unknown> = {}
): string | undefined => {
  if (stringEmpty(value)) return

  return value?.replace(/{(\w+)}/g, (match: string, field: string) =>
    Object.hasOwn(replacements, field) ? (replacements[field] as string) : match
  )
}

/**
 * Truncates a string and adds an ellipsis to the end
 * @param str
 * @param maxLength
 * @returns Truncated string with '...' added to the end
 */
export const stringTruncate = (str?: string, maxLength = 100) => {
  if (!str) return ''

  const trimmed = str.trim()

  if (trimmed.length <= maxLength) {
    return trimmed
  }

  return `${str.substring(0, maxLength).trim()}...`
}

export const notNullOrUndefined = (val: unknown) => {
  return val !== null && val !== undefined
}

export const sanitizeObject = (obj: any) => {
  const result = Object.keys(obj)
    .filter((k) => ![undefined, null, ''].includes(obj[k]))
    .reduce((o, k) => ({ ...o, [k]: obj[k] }), {})

  return Object.keys(result).length ? result : undefined
}

export const mapLink = (btn: any, additionalProps = {}) => {
  if (!btn) return undefined

  const obj = sanitizeObject({
    text: btn?.text || undefined,
    href: btn?.href || btn?.url || undefined,
    linktype: btn?.linktype || undefined,
    anchor: btn?.anchor || undefined
  })

  if (obj) {
    Object.assign(obj, additionalProps)
  }
  return obj
}

// delay utility function
export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))
