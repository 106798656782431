export default defineNuxtPlugin(() => {
  return {
    provide: {
      titleCase: (str: string) => {
        if (!str) {
          return
        }

        const numerals = ['i', 'ii', 'iii', 'iv']
        const skippedWords = ['and', 'in', 'of']

        return str
          .split(' ')
          .map((word) => {
            if (skippedWords.includes(word)) {
              return word.toLowerCase()
            }

            if (numerals.includes(word)) {
              return word.toUpperCase()
            }

            return word[0].toUpperCase() + word.substring(1).toLowerCase()
          })
          .join(' ')
      }
    }
  }
})
