import { defineStore } from 'pinia'
import { useAuth } from '#auth/runtime/composables'

type NameType = 'single' | 'full'

interface State {
  geolocation: GeolocationPosition | null
  useGeolocation: boolean
  location: models.stores.user.Location
  address: models.stores.user.Address | models.EmptyObject
  userLocation: any
}

export const useUserStore = defineStore('user', {
  state: (): State => ({
    geolocation: null,
    useGeolocation: false,
    location: {
      lat: null,
      lng: null,
      errorMessage: null,
      loc: null,
      // post: null,
      // suburb: null,
      isSearching: false
    },
    userLocation: null,
    address: {}
  }),
  getters: {
    hasGeolocation: (state) =>
      state.geolocation?.coords?.latitude === state.location.lat,
    getUserName: () => (type: NameType) => {
      const $auth = useAuth()
      const user = $auth.user

      if (user != undefined) {
        if (user.givenName === 'TAFE') {
          return user.sn
        }

        return type === 'single'
          ? user.givenName
          : `${user.givenName} ${user.sn}`
      }

      return ''
    },
    getLoc: (state) => () => {
      return state.location.loc
    },
    getLatLng: (state) => () => {
      return { lat: state.location.lat, lng: state.location.lng }
    }
  },
  actions: {
    async findGeolocation() {
      if (!this.geolocation) {
        this.location.isSearching = true

        const data = new Promise<GeolocationPosition>((resolve, reject) => {
          if (!('geolocation' in navigator)) {
            return reject(new Error('Geolocation is not available.'))
          }
          navigator.geolocation.getCurrentPosition(resolve, reject)
        })

        try {
          this.geolocation = await data
          this.location.lat = this.geolocation.coords.latitude
          this.location.lng = this.geolocation.coords.longitude
        } catch (e) {
          if ((e as { code: unknown })?.code === 1) {
            this.location.errorMessage =
              'TAFE NSW was blocked from accessing your location.'
          } else {
            this.location.errorMessage = 'Geolocation unavailable.'
          }
          this.useGeolocation = false
        } finally {
          this.location.isSearching = false
        }
      }
    },

    async findSuburb() {
      if (this.geolocation) {
        const { $maps } = useNuxtApp()
        const lat = this.geolocation?.coords?.latitude ?? null
        const lng = this.geolocation?.coords?.longitude ?? null

        await $maps.reverseGeocode(lat, lng)?.then((res) => {
          this.address = parseAddress(res.results)
          this.location.loc = this.address.suburb
          this.location.lat = this.geolocation?.coords?.latitude ?? null
          this.location.lng = this.geolocation?.coords?.longitude ?? null

          this.useGeolocation = true
        })

        return {
          loc: this.location.loc,
          lat: this.location.lat,
          lng: this.location.lng
        }
      } else if (!this.location.errorMessage) {
        await this.findGeolocation()
        await this.findSuburb()
        return {
          loc: this.address.suburb,
          lat: this.location.lat,
          lng: this.location.lng
        }
      }
      return { loc: null, lat: null, lng: null }
    },

    setLoc(value: models.stores.user.Campus) {
      this.location.loc = value.suburb
      this.location.lat = value.lat
      this.location.lng = value.lng
    },

    setGeolocation(value: boolean) {
      this.useGeolocation = value
    },
    reset() {
      this.useGeolocation = false
      this.location.loc = null
      this.location.lat = null
      this.location.lng = null
      this.location.errorMessage = null
      this.location.isSearching = false
    }
  }
})

function getField(
  locality: Array<google.maps.GeocoderAddressComponent>,
  type: string
) {
  return locality?.find((element) => element.types?.includes(type))
}

function parseAddress(result: Array<google.maps.GeocoderResult>) {
  const locality =
    result?.find((element) => element.types?.includes('locality'))
      ?.address_components || []

  const address: models.stores.user.Address = {
    suburb: getField(locality, 'locality')?.short_name || null,
    postcode: getField(locality, 'postal_code')?.short_name || null,
    state:
      getField(locality, 'administrative_area_level_1')?.short_name || null,
    country: getField(locality, 'country')?.long_name || null
  }

  return address
}
